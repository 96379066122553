import React, { useState, useEffect } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Carousels.scss';

const Carousels = () => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        const query = '*[_type == "carousel"]';
    
        client.fetch(query)
          .then((data) => {
            setImages(data);
          })
      }, []);
    

  return (
  <>
   <h2 className='head-text'>Previous Work</h2>
    <div className='app__carousel-container'>
        <Carousel
          useKeyboardArrows={true}
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          className="app__carousel-slider"
        >
          
          { images.map((image) => (
            <>
              <div>
                <img src={urlFor(image.imageurl)} alt={image.title}  />
                <p className='p-text'>{image.title}</p>
              </div>
            </>
        ))}
        </Carousel>
      </div>
  </> 
    );
}
export default AppWrap(MotionWrap(Carousels, 'app__carousel'),
 'carousels', 'app__primarybg');