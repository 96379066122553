import React from 'react'
import { AiFillLinkedin } from 'react-icons/ai';
import { AiFillGithub } from 'react-icons/ai';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
        <a href="https://www.linkedin.com/in/dena-tariq-b555b6239" ><AiFillLinkedin /></a>
        </div>
        <div>
        <a href="https://github.com/DenaZ1" ><AiFillGithub /></a>
        </div>
    </div>
  )
}

export default SocialMedia